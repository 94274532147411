import Carousel from "react-bootstrap/Carousel";

import slide_1 from "../../img/Header/slide-1.jpg";
import slide_2 from "../../img/Header/slide-2.png";
import slide_3 from "../../img/Header/slide-3.png";

import "./Title.scss";
import { TitleText } from "../TitleText/TitleText";
function Title() {
  return (
    <Carousel className="mainHeader" variant="dark" autoPlay={false}>
      <Carousel.Item interval={10000} autoPlay={false} className="item">
        <img src={slide_1} alt="First slide" />
        <Carousel.Caption className="text">
          <TitleText title="ЛІТНІЙ СЕЗОН" text="марафону місяць читання" />
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={10000} autoPlay={false} className="item">
        <img src={slide_2} alt="Second slide" />
        <Carousel.Caption className="text">
          <TitleText title="СПІЛЬНІ ЧИТАННЯ" text="разом читати веселіше" />
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={10000} autoPlay={false} className="item">
        <img src={slide_3} alt="Third slide" />
        <Carousel.Caption className="text">
          <TitleText
            title="12 ГОДИН ЧИТАННЯ"
            text="читай ще більше разом з нами"
          />
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Title;
