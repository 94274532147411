import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/fire";
import ReactMarkdown from "react-markdown";
import { NavBar } from "../../components/NavBar/NavBar";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import { Helmet } from "react-helmet";
import { Scroll } from "../../components/Scroll/Scroll";
import AdSense from "react-adsense";

import "./Article.scss";
import { SingleArticle } from "../../components/SingleArticle/SingleArticle";
import { menuItemsArticle } from "../../assets/MenuItems";

export const Article = ({ articles }) => {
  const { id } = useParams();

  const article = articles.filter((item) => item.id === id)[0];
  const sortArticles = articles
    .filter((item) => item.id !== id)
    .sort((a, b) => Date.parse(String(b.time)) - Date.parse(String(a.time)))
    .slice(0, 3);

  const UpdatePost = async () => {
    const docRef = doc(db, "Articles", id);
    await updateDoc(docRef, { views: ++article.views });
  };

  useEffect(() => {
    article && UpdatePost();
    window.scrollTo(0, 0);
  }, [article]);

  return (
    <>
      {article && (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <meta name='description' content={`${article.title}`} />
            <title>{`${article.title}`}</title>
            <meta http-equiv='X-UA-Compatible' content='IE=edge' />
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1.0'
            />
          </Helmet>
          <NavBar menuItems={menuItemsArticle} />
          <div className='article'>
            <div className='head'>
              <img src={article.image} alt='' />
              <div className='title'>
                <p>{article.title}</p>
                <div className='desc'>
                  <span className='time'>{article.time}</span>
                  <span className='chapter'>{article.chapter}</span>
                  <div className='views'>
                    <ClassOutlinedIcon />
                    <span>{article.views}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='text'>
              <ReactMarkdown>{article.text}</ReactMarkdown>
            </div>
            <div className='article__advertising'>
              <div className='article__advertising__item'>
                <AdSense.Google
                  className='adsbygoogle'
                  client='ca-pub-5371468088319298'
                  slot='7284375568'
                  style={{
                    display: "inline-block",
                    width: "300px",
                    height: "70px",
                  }}
                  format=''
                />
              </div>
              <div className='article__advertising__item'>
                <AdSense.Google
                  className='adsbygoogle'
                  client='ca-pub-5371468088319298'
                  slot='7284375568'
                  style={{
                    display: "inline-block",
                    width: "300px",
                    height: "70px",
                  }}
                  format=''
                />
              </div>
            </div>
            <div className='other__articles'>
              {sortArticles
                .filter((post) => post.status === "Публиковать")
                .sort((a, b) => b.articleId - a.articleId)
                .map((item) => (
                  <div className='item' key={item.id}>
                    <SingleArticle article={item} />
                  </div>
                ))}
            </div>
          </div>
          <Scroll />
        </>
      )}
    </>
  );
};
