import { Helmet } from "react-helmet";
import { NavBar } from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import "./ReadAlready.scss";
import { menuItemsArticle } from "../../assets/MenuItems";
import { Books } from "./Books";

const booksDB2023 = [
  {
    name: "Грудень",
    id: "dec23",
    link: "https://fas.st/qGLUD",
  },
  {
    name: "Листопад",
    id: "nov23",
    link: "https://fas.st/LRJoTj",
  },
  {
    name: "Жовтень",
    id: "oct23",
    link: "https://fas.st/Aj9N6",
  },
  {
    name: "Вересень",
    id: "sept23",
    link: "https://fas.st/fsPrTw",
  },
  {
    name: "Серпень",
    id: "aug23",
    link: "https://bookchef.ua/product/dim-u-voloshkovomu-mori",
  },
  {
    name: "Липень",
    id: "jul23",
    link: "https://artbooks.ua/sim_cholovikiv_evelyn_hugo",
  },

  { name: "Червень", id: "jun23", link: "https://fas.st/DFLaeu" },
  { name: "Травень", id: "may23", link: "https://fas.st/8Dj2fx" },
  { name: "Квітень", id: "apr23", link: "https://fas.st/I4iusB" },
  { name: "Березень", id: "mar23", link: "https://fas.st/YPwPk" },
  { name: "Лютий", id: "feb23", link: "https://fas.st/63uEQ" },
  { name: "Січень", id: "jan23", link: "https://fas.st/kTSvmB" },
];

const booksDB2022 = [
  { name: "Грудень", id: "dec22", link: "https://fas.st/GjLlV" },
  { name: "Листопад", id: "nov22", link: "https://fas.st/PYM0to" },
  { name: "Вересень", id: "sept22", link: "https://fas.st/vcyal" },
];

const booksDB2024 = [
  { name: "Січень", id: "jan24", link: "#" },
  { name: "Лютий", id: "fab24", link: "#" },
  { name: "Березень", id: "march24", link: "#" },
  { name: "Квітень", id: "apr24", link: "#" },
  { name: "Травень", id: "may24", link: "#" },
];

const ReadAlready = () => {
  return (
    <>
      <NavBar menuItems={menuItemsArticle} />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Прочитані книжки" />
        <title>Статті</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="allbooks__wrapper">
        <div className="allbooks__title">Прочитано у 2024 році</div>
        <div className="root__year">
          <Books props={booksDB2024} />
        </div>
        <div className="allbooks__title">Прочитано у 2023 році</div>
        <div className="root__year">
          <Books props={booksDB2023} />
        </div>
        <div className="allbooks__title">Прочитано у 2022 році</div>
        <div className="root__year">
          <Books props={booksDB2022} />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ReadAlready;
